import React, { Component, useState, useEffect } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import DropDownPicker from "react-native-dropdown-picker";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Modal,
  FlatList,
  Picker,
  SectionList,
} from "react-native";

import Axios from "axios";
import { Button, Headline } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import TextInput from "../components/TextInput";
import {
  CategoryValidator,
  nameValidator,
  OutletValidator,
  priceValidator,
} from "../helpers/nameValidator";
const OutletMenuDesc = () => {
  const [showAlert, setshowAlert] = useState({ id: 0, status: false });
  const [showMsg, setshowMsg] = useState({ title: "", msg: "", status: false });
  const [AddEditVisiable, setAddEditVisiable] = useState(false);
  const [OutletDetail, setOutletDetail] = useState([]);
  const [Outletvalue, setOutletvalue] = useState(0);
  const [OutletDesc, setOutletDesc] = useState([]);
  const [OutletDescMasterDetail, setOutletDescMasterDetail] = useState({
    value: "",
    error: "",
    id: 0,
    outletId: 0,
  });
  useEffect(() => {
    Axios.get("https://trustqube.net:3010/api/getOutletDetail").then(
      (responce) => {
        const data = responce.data;
        const tList = data.map((d) => ({
          label: d.Outlet_name,
          value: d.id,
        }));
        setOutletDetail(tList);
      }
    );
    showDescDetail();
  }, []);

  const setOutletData = (val) => {
    if (val !== 0) {
      // setOutletvalue(val);
      setOutletDescMasterDetail({
        ...OutletDescMasterDetail,
        outletId: val,
      });
    }
  };
  const showDescDetail = () => {
    Axios.get("https://trustqube.net:3010/api/getOutlet_menu_desc").then(
      (responce) => {
        const groupedArray = _.groupBy(responce.data, "outlet_name");

        let result = [];
        for (const [key, value] of Object.entries(groupedArray)) {
          result.push({
            title: key,
            data: value,
          });
        }
        setOutletDesc(result);
      }
    );
  };

  const hideAlert = () => {
    setshowAlert({ id: 0, status: false });
  };
  const showAlertDialog = (tid) => {
    setshowAlert({ id: tid, status: true });
  };

  const DeleteItem = () => {
    Axios.get(
      `https://trustqube.net:3010/api/deleteOutletDesc/${showAlert.id}`
    ).then((responce) => {
      showDescDetail();
      setshowAlert({ id: 0, status: false });
      setshowMsg({
        title: "Deleted...",
        msg: "Selected Record Sucessfully Deleted...",
        status: true,
      });
    });
  };
  const SaveData = () => {
    const NameError = nameValidator(OutletDescMasterDetail.value);
    const OutletError = OutletValidator(OutletDescMasterDetail.outletId);
    if (NameError || OutletError) {
      setOutletDescMasterDetail({
        ...OutletDescMasterDetail,
        error: NameError ? NameError : OutletError ? OutletError : "",
      });
      return;
    }
    Axios.post("https://trustqube.net:3010/api/addEditOutletDesc", {
      Descid: OutletDescMasterDetail.id,
      Desc: OutletDescMasterDetail.value,
      outletid: OutletDescMasterDetail.outletId,
    }).then((responce) => {
      setOutletDescMasterDetail({
        value: "",
        error: false,
        id: 0,
        outletid: 0,
      });
      setAddEditVisiable(false);
      showDescDetail();
      setshowMsg({
        title: "Record Saved...",
        msg: "Record Sucessfully Saved...",
        status: true,
      });
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={AddEditVisiable}
        onRequestClose={() => {
          setAddEditVisiable(false);
        }}
      >
        <View style={style.centeredView}>
          <View style={style.modalView}>
            <Text>Outlet</Text>
            <View
              style={{
                flex: 1,
                marginHorizontal: 20,
                justifyContent: "center",
              }}
            >
              <Picker
                mode="dropdown"
                selectedValue={OutletDescMasterDetail.outletId}
                onValueChange={setOutletData}
              >
                <Picker.Item
                  color="#0087F0"
                  label="Select Outlet..."
                  value={0}
                  index={0}
                  key={0}
                />
                {OutletDetail.map((item, index) => (
                  <Picker.Item
                    color="#0087F0"
                    label={item.label}
                    value={item.value}
                    index={index}
                    key={item.value}
                  />
                ))}
              </Picker>
            </View>
            <TextInput
              label="Desc."
              returnKeyType="next"
              value={OutletDescMasterDetail.value}
              onChangeText={(text) =>
                setOutletDescMasterDetail({
                  ...OutletDescMasterDetail,
                  value: text,
                  error: "",
                })
              }
              error={!!OutletDescMasterDetail.error}
              errorText={OutletDescMasterDetail.error}
            />
            <View>
              <Button mode="outlined" onPress={SaveData}>
                Save
              </Button>
              <Button mode="outlined" onPress={() => setAddEditVisiable(false)}>
                close
              </Button>
            </View>
          </View>
        </View>
      </Modal>

      <View>
        <Text style={style.heading}>Outlet Description Detail</Text>
      </View>
      <View style={[style.itemContainer]}>
        <Button
          style={style.ButtonStyle}
          labelStyle={{ fontSize: 12 }}
          mode="contained"
          onPress={() => {
            setAddEditVisiable(true);
          }}
        >
          <Text>New Description</Text>
        </Button>
      </View>

      <SectionList
        sections={OutletDesc}
        renderItem={({ item }) => (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 15,
              paddingVertical: 3,
              marginVertical: 5,
              backgroundColor: "#f7f9fa",
            }}
          >
            <Text style={{ color: "#000000", fontSize: 15 }}>
              {item.description}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Button
                mode="contained"
                labelStyle={{ fontSize: 12 }}
                style={{ marginHorizontal: 3 }}
                onPress={() => {
                  setOutletDescMasterDetail({
                    ...OutletDescMasterDetail,
                    value: item.description,
                    id: item.id,
                    outletId: item.outlet_id,
                  });
                  setAddEditVisiable(true);
                }}
              >
                <Text>Edit</Text>
              </Button>
              <Button
                style={{ marginHorizontal: 3 }}
                mode="contained"
                labelStyle={{ fontSize: 12 }}
                onPress={() => {
                  showAlertDialog(item.id);
                }}
              >
                <Text>Delete</Text>
              </Button>
            </View>
          </View>
        )}
        renderSectionHeader={({ section }) => (
          <View
            style={{
              backgroundColor: "#ffb300",
              marginVertical: 5,
            }}
          >
            <Text style={style.sectionHeader}>
              {section.title} ( {section.data.length} )
            </Text>
          </View>
        )}
        keyExtractor={(item, index) => index}
      />

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: "#ecf0f1",
          padding: 8,
        }}
      ></View>

      <AwesomeAlert
        show={showAlert.status}
        showProgress={false}
        title="Delete Confirmation"
        message="Really Want To delete it..."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, Delete It..."
        confirmButtonColor="#DD6B55"
        onCancelPressed={hideAlert}
        onConfirmPressed={DeleteItem}
      />

      <AwesomeAlert
        show={showMsg.status}
        showProgress={false}
        title={showMsg.title}
        message={showMsg.msg}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={() => {
          setshowMsg({ title: "", msg: "", status: false });
        }}
      />
    </View>
  );
};

export default OutletMenuDesc;

const style = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
  },
  card: {
    margin: 4,
    width: 100,
    height: 50,
  },
  heading: {
    backgroundColor: "#00A6A6",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 5,
  },
  ImageStyle: {
    flex: 1,
    alignItems: "flex-start",
    resizeMode: "contain",
  },
  ButtonViewStyle: {
    flexDirection: "row",
    margin: 7,
    justifyContent: "space-around",
  },
  ButtonStyle: {
    margin: 7,
    width: 150,
  },
  gridView: {
    marginTop: 10,
    flex: 1,
  },
  itemContainer: {
    backgroundColor: "#ffb300",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
  },
  ContainerStyle: {
    textAlign: "center",
  },
  TextStyle: {
    color: "#1E1014",
    fontSize: 15,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
    marginTop: 22,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  grid_plan: {
    flexDirection: "row",
    borderRadius: 10,
    height: 70,
    borderWidth: 1,
    backgroundColor: "#EEBA0B",
    padding: 1,
    margin: 5,
    elevation: 2,
    textAlign: "center",
    width: "100%",
  },
  sectionHeader: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    border: 1,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#0b1575",
  },
});
